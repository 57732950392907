import React from "react";

// images
import photography1 from "../images/PNS_PHOTOGRAPHY_1.jpg";
import photography2 from "../images/PNS_PHOTOGRAPHY_2.jpg";
import photography3 from "../images/PNS_PHOTOGRAPHY_3.jpg";
import photography4 from "../images/PNS_PHOTOGRAPHY_4.jpg";

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="photography">
      <div className="bg-white sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          photography
        </h1>
      </div>
      <p className="font-second_font font-light  text-2xl my-4 md:w-5/6" tabIndex="0">
        Our photography always has a modern and technical feel. When used as banners on web pages be 
        sure to use a dark overlay so text can be legible and accessible.
      </p>

      <h2 className="heading-2 my-6" tabIndex="0">
          photography art direction
      </h2>
      <figure className="">
        <img
            alt="photography example"
            className="block mb-1 mx-auto w-full"
            src={photography1}
          />
        <figcaption className="font-second_font font-light text-sm uppercase">
          If using photography with people in it, make sure there is enough space for text to not overlap with the image.</figcaption>
      </figure>
      <div className="grid grid-cols-3 gap-8 my-8">
        <figure className="">
          <img
              alt="photography example"
              className="block mb-1 mx-auto w-full"
              src={photography2}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Textures on stock photos should resemble a technical feel.</figcaption>
        </figure>
        <figure className="">
          <img
              alt="photography example"
              className="block mb-1 mx-auto w-full"
              src={photography3}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Like people photography, texture photography on banners should have enough space for text legibility.</figcaption>
        </figure>
        <figure className="">
          <img
              alt="photography example"
              className="block mx-auto w-full"
              src={photography4}
            />
          <figcaption className="font-second_font font-light text-sm uppercase">
            Product shots should be cleanly isolated and saved as transparent PNGs.</figcaption>
        </figure>
      </div>
    </section>
  );
}

export default PhotographySection;