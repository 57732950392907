import React from "react";

// images
import masterLogo from "../images/PANASAS_LOGO_Master.svg";
import logoMark from "../images/PANASAS_LOGO-MARK_Master.svg";
import masterLogoColor from "../images/PANASAS_LOGO_Color.svg";
import masterLogoGrayscale from "../images/PANASAS_LOGO_Grayscale.svg";
import masterLogoBlack from "../images/PANASAS_LOGO_Black.svg";
import masterLogoWhite from "../images/PANASAS_LOGO_White.svg";
import logoMarkColor from "../images/PANASAS_LOGO-MARK_Color.svg";
import logoMarkGrayscale from "../images/PANASAS_LOGO-MARK_Grayscale.svg";
import logoMarkBlack from "../images/PANASAS_LOGO-MARK_Black.svg";
import logoMarkWhite from "../images/PANASAS_LOGO-MARK_White.svg";
import logoIncorrect1 from "../images/PANASAS_Incorrect-Use-A.jpg";
import logoIncorrect2 from "../images/PANASAS_Incorrect-Use-B.jpg";
import logoIncorrect3 from "../images/PANASAS_Incorrect-Use-C.jpg";
import logoIncorrect4 from "../images/PANASAS_Incorrect-Use-D.jpg";
import logoIncorrect5 from "../images/PANASAS_Incorrect-Use-E.jpg";
import logoIncorrect6 from "../images/PANASAS_Incorrect-Use-F.jpg";
import logoIncorrect7 from "../images/PANASAS_Incorrect-Use-G.jpg";
import logoIncorrect8 from "../images/PANASAS_Incorrect-Use-H.jpg";
import logoIncorrect9 from "../images/PANASAS_Incorrect-Use-I.jpg";

function LogoSection() {
  return (
    <section className="text-left mt-20 w-full" id="logo">
      <div className="bg-white sticky top-0 z-10">
        <h1 className="heading-1 font-thin" tabIndex="0">
          logo
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        The Panasas corporate logo is the most visible graphic element of our company and brand.
        It is the official signature on all our communications and a guarantee of the quality of Panasas products and services.
      </p>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Correct usage of the logo is important for building recognition of the Panasas brand as well as protecting 
        our valuable trademark rights. Position, color, and spatial and proportional relationships are predetermined 
        and should never be altered.
      </p>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Use the graphic files provided; do not attempt to reproduce this logo on your own. Contact Panasas marketing 
        department for approval of any logo usage which seems questionable.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY LOGOS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Our master logo contains the full Panasas name and the logo symbol contains our &#39;Infinity&#39; emblem.
      </p>
      <div className="grid grid-cols-2 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="primary brand logo image"
            className="block mx-auto w-full"
            src={masterLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">master logo</figcaption>
        </figure>
        <figure tabIndex="0">
          <img
            alt="primary brand logo image mark"
            className="block mx-auto w-full"
            src={logoMark}
          />
          <figcaption className="text-sm mt-1 uppercase">logo symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        MASTER LOGO
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Always maintain a distinct contrast between the Panasas logo and the color of the background on 
        which it appears. Always allow sufficient space around the logo.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="master logo - color"
            className="block mx-auto w-full"
            src={masterLogoColor}
          />
          <figcaption className="text-sm mt-1 uppercase">blue logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - grayscale"
            className="block mx-auto w-full"
            src={masterLogoGrayscale}
          />
          <figcaption className="text-sm mt-1 uppercase">grayscale logo</figcaption>
        </figure>
        <figure>
          <img
            alt="logo image placeholder"
            className="block mx-auto w-full"
            src={masterLogoBlack}
          />
          <figcaption className="text-sm mt-1 uppercase">black logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - white"
            className="block mx-auto w-full"
            src={masterLogoWhite}
          />
          <figcaption className="text-sm mt-1 uppercase">white logo</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        LOGO SYMBOL
      </h2>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        There are instances where the &#39;Infinity&#39; logo symbol may be used on its own. Careful consideration 
        should be taken when using the symbol instead of the master logo. To help decide, ask yourself: 
        Will my audience know the Infinity stands for Panasas? The symbol may be used for social media 
        profile avatars, internal branded schwag, and on-location branded collateral.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - color"
            className="block mx-auto w-full"
            src={logoMarkColor}
          />
          <figcaption className="text-sm uppercase mt-1">blue Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - grayscale"
            className="block mx-auto w-full"
            src={logoMarkGrayscale}
          />
          <figcaption className="text-sm uppercase mt-1">grayscale Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - black"
            className="block mx-auto w-full"
            src={logoMarkBlack}
          />
          <figcaption className="text-sm uppercase mt-1">Black Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - white"
            className="block mx-auto w-full"
            src={logoMarkWhite}
          />
          <figcaption className="text-sm uppercase mt-1">White Symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        INCORRECT LOGO USAGE
      </h2>
      <ol className="text-lg my-4 md:w-3/4 list-upperAlpha list-inside">
        <div className="grid md:grid-flow-col md:grid-cols-2 md:grid-rows-5">
          <li>Never stretch the logo horizontally</li>
          <li>Never stretch the logo vertically</li>
          <li>Never alter the perspective</li>
          <li>Never place logo on a busy texture</li>
          <li>Never add gradients or special effects</li>
          <li>Never change the proportions of the text</li>
          <li>Never place the logo in a shape </li>
          <li>Never add elements to the logo</li>
          <li>Never add a tagline to the logo</li>
        </div>
      </ol>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-8 my-4">
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block mx-auto w-full"
              src={logoIncorrect1}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">A</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block mx-auto w-full"
              src={logoIncorrect2}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">B</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full border border-solid border-primary_color"
              src={logoIncorrect3}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">C</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect4}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">D</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect5}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">E</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect6}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">F</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect7}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">G</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect8}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">H</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect9}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">I</figcaption>
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/Panasas_Logo-Package.zip`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download logo 
          pack in SVG, EPS and PNG.</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 sm:mt-10 mt-24">
        <a href={`/downloads/PANASAS_Logo-Guide.pdf`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download logo 
          usage guide PDF.</p>
      </div>
    </section>
  );
}

export default LogoSection;