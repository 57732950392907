import React from "react";

// images
// import icons1 from "../images/Icon-1.svg";
import icons2 from "../images/Icon-2.svg";
import icons3 from "../images/Icon-3.svg";
import icons4 from "../images/Icon-4.svg";
import icons5 from "../images/Icon-5.svg";
import icons6 from "../images/Icon-6.svg";

function PhotographySection() {
  return (
    <section className="text-left mt-20 w-full" id="iconography">
      <div className="bg-white sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          ICONOGRAPHY & GRAPHICS
        </h1>
      </div>
      <p className="font-second_font font-light  text-2xl my-4 md:w-5/6" tabIndex="0">
        Iconography used in collateral and on the web should be consistent in color and style. All icons 
        should use the Panasas Blue and Panasas Medium Grey as the primary palette. The style should be 
        clean, thin line work with rarely any fills in the shapes.
      </p>
            <p className="font-second_font font-light  text-2xl my-4 md:w-5/6" tabIndex="0">
       Graphics and diagrams used on the site should follow a similar style as the icons. 
       Please only use colors from the available palette, and the Roboto font.
      </p>

      <div className="grid grid-cols-3 md:grid-cols-6-icons gap-8 my-4">
        {/* <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons1}
          />
        </figure> */}
        <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons2}
          />
        </figure>
        <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons3}
          />
        </figure>
        <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons4}
          />
        </figure>
        <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons5}
          />
        </figure>
        <figure>
          <img
            alt="icon example"
            className="block mx-auto w-full"
            src={icons6}
          />
        </figure>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/Panasas_Icon-Library_2.0.zip`} className="no-underline" download icons>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download our icon library.</p>
      </div>
           <div className="flex flex-col md:flex-row h-16 sm:mt-10 mt-24">
        <a href={`/downloads/Panasas_Graphics.zip`} className="no-underline" download icons>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download our graphics and diagrams.</p>
      </div>
    </section>
  );
}

export default PhotographySection;