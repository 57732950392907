import React from "react";

// images
// no images

function TypographySection() {
  return (
    <section className="text-left mt-20 w-full" id="typography">

      <div className="bg-white sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          typography
        </h1>
      </div>
      <p className="font-second_font my-4 md:w-5/6" tabIndex="0">
        Written communications are an essential tool in projecting the Panasas brand, consistent typography
        plays a significant role in achieving this goal.
      </p>
      <p className="font-second_font my-4 md:w-5/6" tabIndex="0">
        &#39;Gotham&#39; was selected for its classic yet rounded/friendly letterforms as our print font and should
        be used in collateral and trade show displays. 
      </p>
      <p className="font-second_font my-4 md:w-5/6" tabIndex="0">
        ‘Roboto’ and &#39;Roboto Condensed&#39; are our digital brand font and should be used as often as possible
        on the web. We use all weights of this family. &#39;Roboto Condensed&#39; is used for our headlines, while 
        &#39;Roboto&#39; is used for body copy.
      </p>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/PANASAS_TYPOGRAPHY.pdf`} className="no-underline" download>
          <button className="btn" id="download-font-sheet">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download typography  
        sample and spec sheet PDF.</p>
      </div>
      <div className="flex flex-col h-16 md:flex-row sm:mt-10 mt-24">
        <a href={`/downloads/_Panasas_Typography.zip`} className="no-underline" download>
          <button className="btn" id="download-font-package">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download fonts package.</p>
      </div>
    </section>
  );
}

export default TypographySection;