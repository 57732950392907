import React from 'react';

// images
// no images

function ColorSection() {
  return (
    <section className="text-left mt-20 w-full" id="color">
      <div className="bg-white sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          COLOR
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Colors play an important role in brand recognition, therefore
        consistency is crucial. These colors were selected to reflect Panasas’
        modern technology.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY COLORS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Panasas Blue and Panasas Medium Grey are our primary brand colors.
        Panasas Blue is used for the logo and CTAs while Panasas Medium Grey is
        used as a background fill and headlines/body copy.
      </p>
      <div className="grid grid-cols-2 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-primary_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Panasas Primary Blue
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-4/5 md:w-2/3">
              <div>HEX</div> <div>#00a7e1</div>
              <div>RGB</div> <div>0,167,225</div>
              <div>CMYK</div> <div>81,12,1,0</div>
              <div>PANTONE</div> <div>2995 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-primary_color_blue w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Panasas Blue
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 md:w-2/3">
              <div>HEX</div> <div>#0078a8</div>
              <div>RGB</div> <div>0,120,168</div>
              <div>CMYK</div> <div>67,52,44,17</div>
              <div>PANTONE</div> <div>431 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-panasas_green w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Panasas Green
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-4/5 md:w-2/3">
              <div>HEX</div> <div>#32b334</div>
              <div>RGB</div> <div>50,179,52</div>
              <div>CMYK</div> <div>76,1,100,1</div>
              <div>PANTONE</div> <div>361 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-primary_color_dark_slate w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Panasas Dark Slate
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-4/5 md:w-2/3">
              <div>HEX</div> <div>#243746</div>
              <div>RGB</div> <div>36,55,70</div>
              <div>CMYK</div> <div>86,69,50,46</div>
              <div>PANTONE</div> <div>7546 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-second_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">
              Panasas Medium Gray
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 md:w-2/3">
              <div>HEX</div> <div>#46525b</div>
              <div>RGB</div> <div>70,82,91</div>
              <div>CMYK</div> <div>73,59,49,30</div>
              <div>PANTONE</div> <div>431C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h2 className="heading-2" tabIndex="0">
        SECONDaRY COLoRS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        The bright yellow, orange, red and purple are secondary colors that
        should be used sparingly in print and rarely used on the website.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-s1 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#FFB71B</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">255,183,27</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">32,0,95,0</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">1235 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s2 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#F4633A</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">244,99,58</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">0,73,85,0</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">2026 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-panasas_secondary_red w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#D2232A</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">210,35,42</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">15,100,100,0</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">1795 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-s3 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#512179</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">81,33,121</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">81,100,0,9</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">3555 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h2 className="heading-2" tabIndex="0">
        Grays
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Darker and lighter grays are our gray brand colors. You will see these
        used in various areas on our website to help with page sections,
        headlines, and iconography.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-g1 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#25282a</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">37,40,42</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">77,66,59,61</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">425 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-g2 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#434243</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">67,66,67</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">67,61,58,44</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">4287 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-g4 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#968f87</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">151,140,135</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">35,36,32,10</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">408 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-g5 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#c8c8c8</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">200,200,200</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">21,16,0,17</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">720 C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-g6 w-full h-32"></div>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div>{' '}
              <div className="w-1/2">#f1f2f2</div>
              <div className="w-1/2">RGB</div>{' '}
              <div className="w-1/2">241,242,242</div>
              <div className="w-1/2">CMYK</div>{' '}
              <div className="w-1/2">0,0,0,5</div>
              <div className="w-1/2">PANTONE</div>{' '}
              <div className="w-1/2">Cool Grey 1C</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a
          href={`/downloads/Panasas_Color_Palette_Update-2.pdf`}
          className="no-underline"
          download
        >
          <button className="btn" id="download-logo-mark">
            download
          </button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">
          Download color palette spec sheet PDF.
        </p>
      </div>
    </section>
  );
}

export default ColorSection;
